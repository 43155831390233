import React, { useEffect, useState } from 'react';
import { gsap } from 'gsap';
import ScrollTrigger from 'gsap/ScrollTrigger';
import Header from '../components/Header';
import { Box, Button, Typography, Container, Tabs, Tab, Dialog, DialogTitle, DialogContent, DialogActions, Avatar, IconButton } from '@mui/material';
import Spline from '@splinetool/react-spline';
import { Card, CardContent, Grid } from '@mui/material';
import { Chip } from '@mui/material';

import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import { Navigation, Pagination } from 'swiper/modules';

import { FaLink } from 'react-icons/fa';
import { ArrowBackIos, ArrowForwardIos } from '@mui/icons-material';

import { Email as EmailIcon, LinkedIn as LinkedInIcon, WhatsApp as WhatsAppIcon, Instagram as InstagramIcon, Facebook as FacebookIcon } from '@mui/icons-material';

import Backdrop from '@mui/material/Backdrop';
import CircularProgress from '@mui/material/CircularProgress';

import AOS from 'aos';
import 'aos/dist/aos.css'; // You can also use <link> for styles
AOS.init();


gsap.registerPlugin(ScrollTrigger);


const projects = {
    webApps: [
        {
            title: 'Shubadinam',
            description: 'A fully functional web application that helps users get their birthdays according to traditional methods and sends reminder notifications.',
            link: 'https://shubadinam.com',
            image: require("../images/shubadinam.png"),
            button: () => window.open('https://shubadinam.com', '_blank')
        },
        {
            title: 'Traveltil',
            description: 'Developed a user-friendly and responsive interface for Traveltil.',
            link: 'https://www.traveltill.com/',
            image: require("../images/traveltil.png"),
            button: () => window.open('https://www.traveltill.com/', '_blank')
        },
    ],
    portfolios: [
        {
            title: 'NapthalineGerard',
            description: 'Developed a portfolio website for NapthalineGerard.',
            link: 'https://serene-jalebi-45f177.netlify.app',
            image: require("../images/portfolio-1.png"),
            button: () => window.open('https://serene-jalebi-45f177.netlify.app/', '_blank')
        },
    ],
    apps: [
        {
            title: 'Shubadinam',
            description: 'I am currently working on an upcoming project called "Shubadinam", which is a fully functional web application designed to help users get their birthdays according to traditional methods and receive reminder notifications. Stay tuned for its launch!',
            link: 'https://shubadinam.com',
            image: '/images/shubadinam.png',
            button: () => window.open('https://shubadinam.com', '_blank')
        },
    ]
};

const testimonials = [
    {
        name: 'Surya',
        title: 'CEO of Shubadinam',
        image: '/images/john-doe.jpg',
        text: 'This was a fantastic experience! Abishek was professional and delivered the project on time. I highly recommend his services.',
    },
    {
        name: 'Jane Smith',
        title: 'CTO of TechSolutions',
        image: '/images/jane-smith.jpg',
        text: 'The quality of work exceeded our expectations. The UI/UX design was particularly impressive, making our application stand out.',
    },
    {
        name: 'Mike Johnson',
        title: 'Project Manager at BuildIt',
        image: '/images/mike-johnson.jpg',
        text: 'Great communication and technical skills. The project was completed flawlessly, and the final product was exactly what we envisioned.',
    },
];

const phrases = [
    "Hold Tight, Something Amazing is Coming!",
    "We're Almost There!",
    "Just a Moment... Great Things Ahead!",
    "Exciting Things Are on Their Way!",
    "Thanks for Your Patience, Big Reveal Soon!",
    "Get Ready for Something Awesome!",
    "We’re Cooking Up Something Special Just for You!",
    "Stay Tuned, Big Surprises Are Coming!"
];

const LandingPage = () => {


    const [open, setOpen] = useState(true);
    const [currentPhrase, setCurrentPhrase] = useState('');

    useEffect(() => {
        const getRandomPhrase = () => {
            const randomIndex = Math.floor(Math.random() * phrases.length);
            return phrases[randomIndex];
        };

        setCurrentPhrase(getRandomPhrase());

        const timer = setTimeout(() => {
            setOpen(false);
        }, 5000);
        return () => clearTimeout(timer);
    }, []);



    useEffect(() => {
        gsap.to(".section-1 .box-content", {
            opacity: 0,
            scrollTrigger: {
                trigger: ".section-1",
                start: "top top",
                end: "200 top",
                scrub: 0.1,
            }
        });

        const tl = gsap.timeline({
            scrollTrigger: {
                trigger: ".section-1",
                start: "top top",
                end: "bottom top",
                scrub: true,
            }
        });

        tl.fromTo(".hero-image",
            { scale: 1, x: 0, y: 0 },
            {
                scale: () => {
                    if (window.innerWidth >= 992) {
                        return 2;
                    } else {
                        return 1.3;
                    }
                },
                y: 100,
                duration: 1,
                ease: "power1.out"
            }
        )

            .fromTo(".my-name",
                { opacity: 0, y: 0 },
                {
                    scale: 0.8,
                    display: "block",
                    color: "#71c2ff",
                    opacity: 1,
                    y: -20,
                    ease: "power1.inOut"
                }, "-=0.8")


            .to(".hero-image",
                {
                    scale: 1.2,
                    x: () => {
                        if (window.innerWidth >= 992) {
                            return "-300px";
                        } else {
                            return "-30%";
                        }
                    },
                    y: () => {
                        if (window.innerWidth >= 992) {
                            return "400px";
                        } else {
                            return "300px";
                        }
                    },
                    duration: 1,
                    ease: "power1.inOut"
                }



            )


        const tl2 = gsap.timeline({
            scrollTrigger: {
                trigger: ".section-2",
                start: "top center",
                end: "bottom bottom",
                scrub: true,
                // markers: true, // Uncomment to see scroll trigger markers
            }
        });

        tl2.fromTo(
            [".about-title", ".about-content1", ".about-content2"],
            {
                x: "10%",
                opacity: 0
            },
            {
                x: "0%",
                opacity: 1,
                ease: "power1.inOut",
                duration: 0.8 // Adjust the duration as needed
            }
        )
            .to(
                ".section-2",
                {
                    backgroundColor: "#00293e",
                    ease: "power1.inOut",
                    duration: 0.8
                },
                "-=0.1" // Overlap the background color change with the previous animation by 0.8 seconds
            );



    }, []);

    const [selectedTab, setSelectedTab] = useState(0);
    const [openDialog, setOpenDialog] = useState(false);
    const [selectedProject, setSelectedProject] = useState(null);

    const handleTabChange = (event, newValue) => {
        setSelectedTab(newValue);
    };

    const handleProjectClick = (project) => {
        setSelectedProject(project);
        setOpenDialog(true);
    };

    const handleCloseDialog = () => {
        setOpenDialog(false);
        setSelectedProject(null);
    };

    const renderProjects = (projectsArray) => (
        <Grid container spacing={4}>
            {projectsArray.map((project, index) => (
                <Grid item xs={12} sm={6} md={4} key={index} data-aos="zoom-in">
                    <Card sx={{ height: '100%', display: 'flex', flexDirection: 'column', cursor: 'pointer' }} onClick={() => handleProjectClick(project)}>
                        <img src={project.image} alt={project.title} style={{ width: '100%', height: 'auto', borderTopLeftRadius: '4px', borderTopRightRadius: '4px' }} />
                        <CardContent sx={{ flexGrow: 1 }}>
                            <Typography variant="h5" component="div" gutterBottom>
                                {project.title}
                            </Typography>
                            <Typography variant="body2" color="text.secondary">
                                {project.description}
                            </Typography>
                        </CardContent>
                        <Button
                            onClick={project.button}
                            variant="contained"
                            color="primary"
                            startIcon={<FaLink />}
                            sx={{ margin: '16px' }}
                        >
                            Visit Project
                        </Button>
                    </Card>
                </Grid>
            ))}
        </Grid>
    );


    return (
        <div className='overall-container' id='home'>

            <Backdrop
                sx={{
                    color: '#fff',
                    zIndex: (theme) => theme.zIndex.drawer + 1,
                    backdropFilter: 'blur(5px)', // Add blur effect here
                    backgroundColor: 'rgba(0, 0, 0, 0.5)', // Optional: Semi-transparent background color
                }}
                open={open}
            >
                <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                    <CircularProgress color="inherit" />
                    <Typography variant="h6" sx={{ mt: 2 }}>
                        {currentPhrase}
                    </Typography>
                </Box>
            </Backdrop>

            <div className='parent-container'>
                <div className='section-1'>
                    <Header />

                    <Box sx={{ textAlign: "center", backgroundColor: 'black', p: { xs: 4, lg: 10, width: "100%" } }} className='box-content'>
                        <Typography
                            variant='h4'
                            sx={{
                                p: 2,
                                background: 'linear-gradient(45deg, rgb(255 106 194) 0%, rgb(120 228 255) 100%)',
                                backgroundClip: 'text',
                                WebkitBackgroundClip: 'text',
                                WebkitTextFillColor: 'transparent',
                                display: 'inline',
                                fontWeight: "bold",
                                fontSize: { lg: "3rem" }
                            }}
                            data-aos="zoom-in-down"
                            data-duration="1000"
                            data-aos-easing="ease-out"
                        >
                            Mastering the web’s magic: From sleek interfaces to powerful backends, I turn code into captivating digital experiences.
                        </Typography>

                        <Typography variant='h6' sx={{ color: "white", pt: 1 }} data-aos="zoom-in-down" data-duration="2000" data-aos-easing="ease-out">
                            Innovative solutions, user-centric designs, and a passion for building the future of web technology.
                        </Typography>
                    </Box>
                    <Box>
                        <Typography variant='h1' className='my-name' sx={{ color: "white", pt: 1, display: "none", position: "absolute", top: "50%", left: "50%", transform: "translate(-50%,-50%)" }}  >ABISHEK</Typography>
                    </Box>
                    <Box sx={{ height: 250, width: "100%", display: "flex", justifyContent: "center" }} >
                        {/* <Spline className="hero-image" scene="https://prod.spline.design/LFx9Yz08urupRqgu/scene.splinecode" style={{ width: "300px" }} /> */}
                        <Spline className="hero-image" scene="https://prod.spline.design/LFx9Yz08urupRqgu/scene.splinecode" style={{ width: "300px" }} />
                    </Box>
                </div>
                <div className='section-2' id="about">
                    <Box sx={{ display: "flex", width: "50%", flexDirection: "column", p: 5, }}>
                        <Typography variant="h4" sx={{ fontWeight: 'bold', mb: 3, color: "white", opacity: 0 }} className='about-title'>
                            About Me
                        </Typography>
                        <Typography className='about-content1' variant="body1" sx={{ mb: 4, lineHeight: 1.6, color: "white", opacity: 0 }}>
                            I am ABISHEK, skilled Full Stack Web Developer with a strong commitment to building scalable and seamless web applications. Proficient in both front-end and back-end technologies, I specialize in creating responsive user interfaces with React.js and Angular, alongside developing robust server-side applications using Node.js and Express. My goal is to deliver high-quality code that enhances user engagement and aligns with business goals.
                        </Typography>
                        <Typography className='about-content2' variant="body1" sx={{ lineHeight: 1.6, color: "white", opacity: 0 }}>
                            I thrive in transforming complex requirements into efficient digital solutions, utilizing tools like Docker, AWS, and CI/CD pipelines to ensure smooth development and deployment processes. My dedication to continuous learning keeps me abreast of the latest industry advancements, enabling me to develop innovative solutions that drive business value and growth.
                        </Typography>
                    </Box>
                </div>
                <div className='section-3' >
                    <Grid container spacing={4} sx={{ p: { xs: 2, md: '40px' }, backgroundColor: '#000000', color: '#ffffff' }}>

                        {/* Right Column - Professional Experience */}
                        <Grid item xs={12} md={6}>
                            <Card
                                sx={{
                                    background: 'linear-gradient(135deg, #1c1c1c 0%, #2c2c2c 100%)',
                                    color: '#ffffff',
                                    padding: '20px',
                                    boxShadow: '0px 4px 20px rgba(0, 0, 0, 0.5)',
                                    borderRadius: '10px',
                                    transition: 'transform 0.3s, box-shadow 0.3s',
                                    '&:hover': {
                                        transform: 'translateY(-10px)',
                                        boxShadow: '0px 10px 30px rgba(0, 0, 0, 0.7)',
                                    },
                                }}
                            >
                                <CardContent>
                                    <Typography
                                        variant="h5"
                                        component="div"
                                        gutterBottom
                                        sx={{
                                            borderBottom: '2px solid #ffffff',
                                            paddingBottom: '10px',
                                            marginBottom: '20px',
                                            fontWeight: 'bold',
                                        }}
                                    >
                                        PROFESSIONAL EXPERIENCE
                                    </Typography>
                                    <Typography data-aos="fade-left" variant="h6" sx={{ fontWeight: 'bold', color: "#71c2ff" }}>
                                        Full Stack Web Developer – Techcent Innovations | 2023 - Present
                                    </Typography>
                                    <br />
                                    <Typography data-aos="fade-right" variant="body2" paragraph>
                                        • Developed and maintained a traditional birthday reminder service web application, handling both front-end and back-end tasks.
                                    </Typography>
                                    <Typography data-aos="fade-right" variant="body2" paragraph>
                                        • Integrated payment gateways, SMS services, and managed web hosting through AWS, ensuring smooth operation and scalability.
                                    </Typography>
                                    <Typography data-aos="fade-right" variant="body2" paragraph>
                                        • Oversaw the entire web application lifecycle, including daily updates, code maintenance, and problem-solving throughout the project.
                                    </Typography>

                                    <Typography data-aos="fade-left" variant="h6" sx={{ fontWeight: 'bold', marginTop: '30px', color: "#71c2ff" }}>
                                        Front End Web Developer Trainee – Grovita Technologies | 2023
                                    </Typography>
                                    <br />
                                    <Typography data-aos="fade-right" variant="body2" paragraph>
                                        • Developed and maintained front-end functionality for websites using HTML5, CSS3, and JavaScript frameworks.
                                    </Typography>
                                    <Typography data-aos="fade-right" variant="body2" paragraph>
                                        • Built responsive mobile websites utilizing Bootstrap and Foundation for a seamless user experience across devices.
                                    </Typography>
                                    <Typography data-aos="fade-right" variant="body2" paragraph>
                                        • Translated wireframes into visual designs, ensuring adherence to visual and functional specifications.
                                    </Typography>
                                </CardContent>
                            </Card>
                        </Grid>


                        {/* Left Column - Education */}
                        <Grid item xs={12} md={6}>
                            <Card
                                sx={{
                                    background: 'linear-gradient(135deg, #1c1c1c 0%, #2c2c2c 100%)',
                                    color: '#ffffff',
                                    padding: '20px',
                                    boxShadow: '0px 4px 20px rgba(0, 0, 0, 0.5)',
                                    borderRadius: '10px',
                                    transition: 'transform 0.3s, box-shadow 0.3s',
                                    '&:hover': {
                                        transform: 'translateY(-10px)',
                                        boxShadow: '0px 10px 30px rgba(0, 0, 0, 0.7)',
                                    },
                                }}
                            >
                                <CardContent>
                                    <Typography
                                        variant="h5"
                                        component="div"
                                        gutterBottom
                                        sx={{
                                            borderBottom: '2px solid #ffffff',
                                            paddingBottom: '10px',
                                            marginBottom: '20px',
                                            fontWeight: 'bold',
                                        }}
                                    >
                                        EDUCATION
                                    </Typography>
                                    <Typography data-aos="fade-left" variant="h6" sx={{ fontWeight: 'bold', color: "#71c2ff" }}>
                                        Full Stack - React JS Course
                                    </Typography>
                                    <Typography variant="body2" sx={{ color: "#71c2ff" }}>
                                        Vcentry Institute | Guindy, Tamil Nadu 600032, India | 2023
                                    </Typography>
                                    <br />
                                    <Typography data-aos="fade-right" variant="body2" paragraph>
                                        • Comprehensive training in React.js and full-stack development.
                                    </Typography>
                                    <Typography data-aos="fade-right" variant="body2" paragraph>
                                        • Hands-on experience with both front-end and back-end technologies.
                                    </Typography>
                                    <Typography data-aos="fade-right" variant="body2" paragraph>
                                        • Projects included building and deploying full-stack web applications.
                                    </Typography>

                                    <Typography data-aos="fade-left" variant="h6" sx={{ fontWeight: 'bold', marginTop: '30px', color: "#71c2ff" }}>
                                        Bachelor of Science in Mathematics | Grade: Distinction
                                    </Typography>
                                    <Typography variant="body2" sx={{ color: "#71c2ff" }}>
                                        Sri Ramakrishna Mission Vidyalaya College of Arts and Science | Coimbatore | 2019 – 2022
                                    </Typography>
                                    <br />
                                    <Typography data-aos="fade-right" variant="body2" paragraph>
                                        • Developed strong analytical and problem-solving skills through rigorous coursework.
                                    </Typography>
                                    <Typography data-aos="fade-right" variant="body2" paragraph>
                                        • Applied mathematical theories and methods to practical problems.
                                    </Typography>

                                </CardContent>
                            </Card>
                        </Grid>


                    </Grid>
                </div>
                <div className='section-4'>
                    <Grid container spacing={4} sx={{ padding: '40px', backgroundColor: '#000000', color: '#ffffff' }}>
                        {/* Technical Skills */}
                        <Grid item xs={12} >
                            <Typography
                                variant="h4"
                                component="div"
                                gutterBottom
                                sx={{
                                    textAlign: 'center',
                                    color: '#71c2ff',
                                    fontWeight: 'bold',
                                    marginBottom: '30px',
                                }}
                            >
                                TECHNICAL SKILLS
                            </Typography>

                            <Grid container spacing={4}>
                                {/* Front End Skills */}
                                <Grid item xs={12} md={4}>
                                    <Card
                                        data-aos="zoom-in"
                                        sx={{
                                            background: 'linear-gradient(135deg, #2c2c2c 0%, #1c1c1c 100%)',
                                            color: '#ffffff',
                                            padding: '20px',
                                            boxShadow: '0px 4px 20px rgba(0, 0, 0, 0.5)',
                                            borderRadius: '10px',
                                            transition: 'transform 0.3s, box-shadow 0.3s',
                                            '&:hover': {
                                                transform: 'translateY(-10px)',
                                                boxShadow: '0px 10px 30px rgba(0, 0, 0, 0.7)',
                                            },
                                        }}
                                    >
                                        <CardContent>
                                            <Typography
                                                variant="h6"
                                                component="div"
                                                gutterBottom
                                                sx={{
                                                    borderBottom: '2px solid #71c2ff',
                                                    paddingBottom: '10px',
                                                    marginBottom: '20px',
                                                    fontWeight: 'bold',
                                                }}
                                            >
                                                Front End
                                            </Typography>
                                            <Chip label="React js" sx={{ margin: '4px', backgroundColor: '#71c2ff', color: '#ffffff' }} />
                                            <Chip label="HTML 5" sx={{ margin: '4px', backgroundColor: '#f0f0f0', color: '#000000' }} />
                                            <Chip label="CSS 3" sx={{ margin: '4px', backgroundColor: '#2965f1', color: '#ffffff' }} />
                                            <Chip label="Bootstrap 4" sx={{ margin: '4px', backgroundColor: '#563d7c', color: '#ffffff' }} />
                                            <Chip label="MUI" sx={{ margin: '4px', backgroundColor: '#007bff', color: '#ffffff' }} />
                                            <Chip label="GSAP" sx={{ margin: '4px', backgroundColor: '#00d084', color: '#ffffff' }} />
                                            <Chip label="Spline" sx={{ margin: '4px', backgroundColor: '#f7c600', color: '#000000' }} />
                                        </CardContent>
                                    </Card>
                                </Grid>

                                {/* Programming Languages */}
                                <Grid item xs={12} md={4}>
                                    <Card
                                        data-aos="zoom-in"
                                        sx={{
                                            background: 'linear-gradient(135deg, #2c2c2c 0%, #1c1c1c 100%)',
                                            color: '#ffffff',
                                            padding: '20px',
                                            boxShadow: '0px 4px 20px rgba(0, 0, 0, 0.5)',
                                            borderRadius: '10px',
                                            transition: 'transform 0.3s, box-shadow 0.3s',
                                            '&:hover': {
                                                transform: 'translateY(-10px)',
                                                boxShadow: '0px 10px 30px rgba(0, 0, 0, 0.7)',
                                            },
                                        }}
                                    >
                                        <CardContent>
                                            <Typography
                                                variant="h6"
                                                component="div"
                                                gutterBottom
                                                sx={{
                                                    borderBottom: '2px solid #71c2ff',
                                                    paddingBottom: '10px',
                                                    marginBottom: '20px',
                                                    fontWeight: 'bold',
                                                }}
                                            >
                                                Programming Languages
                                            </Typography>
                                            <Chip label="JavaScript" sx={{ margin: '4px', backgroundColor: '#f0db4f', color: '#000000' }} />
                                            <Chip label="Flutter" sx={{ margin: '4px', backgroundColor: '#02569b', color: '#ffffff' }} />
                                        </CardContent>
                                    </Card>
                                </Grid>

                                {/* Back End Skills */}
                                <Grid item xs={12} md={4}>
                                    <Card
                                        data-aos="zoom-in"
                                        sx={{
                                            background: 'linear-gradient(135deg, #2c2c2c 0%, #1c1c1c 100%)',
                                            color: '#ffffff',
                                            padding: '20px',
                                            boxShadow: '0px 4px 20px rgba(0, 0, 0, 0.5)',
                                            borderRadius: '10px',
                                            transition: 'transform 0.3s, box-shadow 0.3s',
                                            '&:hover': {
                                                transform: 'translateY(-10px)',
                                                boxShadow: '0px 10px 30px rgba(0, 0, 0, 0.7)',
                                            },
                                        }}
                                    >
                                        <CardContent>
                                            <Typography
                                                variant="h6"
                                                component="div"
                                                gutterBottom
                                                sx={{
                                                    borderBottom: '2px solid #71c2ff',
                                                    paddingBottom: '10px',
                                                    marginBottom: '20px',
                                                    fontWeight: 'bold',
                                                }}
                                            >
                                                Back End
                                            </Typography>
                                            <Chip label="Node.js" sx={{ margin: '4px', backgroundColor: '#68a063', color: '#ffffff' }} />
                                            <Chip label="MySQL Workbench" sx={{ margin: '4px', backgroundColor: '#00758f', color: '#ffffff' }} />
                                            <Chip label="PhpMyAdmin" sx={{ margin: '4px', backgroundColor: '#1c1c1c', color: '#ffffff' }} />
                                        </CardContent>
                                    </Card>
                                </Grid>

                                {/* Other Skills */}
                                <Grid item xs={12} md={4}>
                                    <Card
                                        data-aos="zoom-in"
                                        sx={{
                                            background: 'linear-gradient(135deg, #2c2c2c 0%, #1c1c1c 100%)',
                                            color: '#ffffff',
                                            padding: '20px',
                                            boxShadow: '0px 4px 20px rgba(0, 0, 0, 0.5)',
                                            borderRadius: '10px',
                                            transition: 'transform 0.3s, box-shadow 0.3s',
                                            '&:hover': {
                                                transform: 'translateY(-10px)',
                                                boxShadow: '0px 10px 30px rgba(0, 0, 0, 0.7)',
                                            },
                                        }}
                                    >
                                        <CardContent>
                                            <Typography
                                                variant="h6"
                                                component="div"
                                                gutterBottom
                                                sx={{
                                                    borderBottom: '2px solid #71c2ff',
                                                    paddingBottom: '10px',
                                                    marginBottom: '20px',
                                                    fontWeight: 'bold',
                                                }}
                                            >
                                                Other Skills
                                            </Typography>
                                            <Chip label="Payment Gateways" sx={{ margin: '4px', backgroundColor: '#ff5c5c', color: '#ffffff' }} />
                                            <Chip label="SMS and Email Integrations" sx={{ margin: '4px', backgroundColor: '#ff8c00', color: '#ffffff' }} />
                                            <Chip label="GitHub" sx={{ margin: '4px', backgroundColor: '#333', color: '#ffffff' }} />
                                        </CardContent>
                                    </Card>
                                </Grid>

                                {/* AWS Hosting */}
                                <Grid item xs={12} md={6}>
                                    <Card
                                        data-aos="zoom-in"
                                        sx={{
                                            background: 'linear-gradient(135deg, #2c2c2c 0%, #1c1c1c 100%)',
                                            color: '#ffffff',
                                            padding: '20px',
                                            boxShadow: '0px 4px 20px rgba(0, 0, 0, 0.5)',
                                            borderRadius: '10px',
                                            transition: 'transform 0.3s, box-shadow 0.3s',
                                            '&:hover': {
                                                transform: 'translateY(-10px)',
                                                boxShadow: '0px 10px 30px rgba(0, 0, 0, 0.7)',
                                            },
                                        }}
                                    >
                                        <CardContent>
                                            <Typography
                                                variant="h6"
                                                component="div"
                                                gutterBottom
                                                sx={{
                                                    borderBottom: '2px solid #71c2ff',
                                                    paddingBottom: '10px',
                                                    marginBottom: '20px',
                                                    fontWeight: 'bold',
                                                }}
                                            >
                                                AWS Hosting
                                            </Typography>
                                            <Chip label="EC2" sx={{ margin: '4px', backgroundColor: '#ff9900', color: '#ffffff' }} />
                                            <Chip label="S3 Bucket" sx={{ margin: '4px', backgroundColor: '#569a31', color: '#ffffff' }} />
                                            <Chip label="CloudFront" sx={{ margin: '4px', backgroundColor: '#c8c8c8', color: '#000000' }} />
                                            <Chip label="Route 53" sx={{ margin: '4px', backgroundColor: '#0052a3', color: '#ffffff' }} />
                                            <Chip label="CI/CD Pipeline" sx={{ margin: '4px', backgroundColor: '#0073e6', color: '#ffffff' }} />
                                            <Chip label="Domain Purchasing" sx={{ margin: '4px', backgroundColor: '#0073e6', color: '#ffffff' }} />
                                            <Chip label="GitHub Actions" sx={{ margin: '4px', backgroundColor: '#000000', color: '#ffffff' }} />
                                        </CardContent>
                                    </Card>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                </div>
                <div className='section-5' id="projects">
                    <Container sx={{ padding: '40px 20px' }}>
                        <Typography
                            variant="h4"
                            component="div"
                            gutterBottom
                            sx={{
                                textAlign: 'center',
                                color: '#71c2ff',
                                fontWeight: 'bold',
                                marginBottom: '30px',
                            }}
                        >
                            My Projects
                        </Typography>

                        {/* Tabs for Project Categories */}
                        <Tabs
                            value={selectedTab}
                            onChange={handleTabChange}
                            centered
                            sx={{ marginBottom: '30px', color: "white" }}
                        >
                            <Tab label="Web Apps" sx={{ color: "white" }} />
                            <Tab label="Portfolios" sx={{ color: "white" }} />
                            <Tab label="Apps" sx={{ color: "white" }} />
                        </Tabs>

                        {/* Render Projects Based on Selected Tab */}
                        {selectedTab === 0 && renderProjects(projects.webApps)}
                        {selectedTab === 1 && renderProjects(projects.portfolios)}
                        {selectedTab === 2 && renderProjects(projects.apps)}

                        {/* Dialog for Project Details */}
                        <Dialog open={openDialog} onClose={handleCloseDialog} maxWidth="md" fullWidth>
                            {selectedProject && (
                                <>
                                    <DialogTitle>{selectedProject.title}</DialogTitle>
                                    <DialogContent>
                                        <Typography variant="body1" gutterBottom>
                                            {selectedProject.description}
                                        </Typography>
                                        <img src={selectedProject.image} alt={selectedProject.title} style={{ width: '100%', height: 'auto', borderRadius: '4px' }} />
                                    </DialogContent>
                                    <DialogActions>
                                        <Button
                                            href={selectedProject.link}
                                            target="_blank"
                                            rel="noopener noreferrer"
                                            variant="contained"
                                            color="primary"
                                            startIcon={<FaLink />}
                                        >
                                            Visit Project
                                        </Button>
                                        <Button onClick={handleCloseDialog} color="secondary">
                                            Close
                                        </Button>
                                    </DialogActions>
                                </>
                            )}
                        </Dialog>
                    </Container>
                </div>
                <div className='section-6'>
                    <Container sx={{ padding: '40px 20px' }}>
                        <Typography
                            variant="h4"
                            component="div"
                            gutterBottom
                            sx={{
                                textAlign: 'center',
                                color: '#71c2ff',
                                fontWeight: 'bold',
                                marginBottom: '30px',
                            }}
                        >
                            Testimonials
                        </Typography>

                        <Swiper
                            spaceBetween={30}
                            slidesPerView={1}
                            pagination={{ clickable: true }}
                            navigation={{ nextEl: '.swiper-button-next', prevEl: '.swiper-button-prev' }}
                            autoplay={{ delay: 3000 }}
                            modules={[Navigation]}
                        >
                            {testimonials.map((testimonial, index) => (
                                <SwiperSlide key={index}>
                                    <Card sx={{ maxWidth: 600, margin: '0 auto', padding: '20px' }}>
                                        <CardContent>
                                            <Grid container spacing={2} alignItems="center">
                                                <Grid item>
                                                    <Avatar src={testimonial.image} alt={testimonial.name} sx={{ width: 56, height: 56 }} />
                                                </Grid>
                                                <Grid item>
                                                    <Typography variant="h6" component="div">
                                                        {testimonial.name}
                                                    </Typography>
                                                    <Typography variant="body2" color="text.secondary">
                                                        {testimonial.title}
                                                    </Typography>
                                                </Grid>
                                            </Grid>
                                            <Typography variant="body1" component="p" sx={{ marginTop: '16px', fontStyle: 'italic' }}>
                                                "{testimonial.text}"
                                            </Typography>
                                        </CardContent>
                                    </Card>
                                </SwiperSlide>
                            ))}

                            {/* Navigation Buttons */}
                            <IconButton className="swiper-button-prev" sx={{ position: 'absolute', top: '50%', left: '0', zIndex: 10, color: '#71c2ff' }}>
                                <ArrowBackIos />
                            </IconButton>
                            <IconButton className="swiper-button-next" sx={{ position: 'absolute', top: '50%', right: '0', zIndex: 10, color: '#71c2ff' }}>
                                <ArrowForwardIos />
                            </IconButton>
                        </Swiper>
                    </Container>
                </div>
                <div className='section-7' id="contact">
                    <Container sx={{ padding: '40px 0' }}>
                        <Typography
                            variant="h4"
                            component="div"
                            gutterBottom
                            sx={{
                                textAlign: 'center',
                                color: '#71c2ff',
                                fontWeight: 'bold',
                                marginBottom: '30px',
                            }}
                        >
                            Get in Touch
                        </Typography>
                        <Box
                            sx={{
                                display: 'flex',
                                flexDirection: 'column',
                                alignItems: 'center',
                            }}
                        >
                            <Typography variant="h6" component="div" gutterBottom sx={{ marginBottom: '20px' }}>
                                Feel free to reach out to me through any of the following platforms:
                            </Typography>
                            <Grid container spacing={2} justifyContent="center">
                                <Grid item>
                                    <Button
                                        data-aos="fade-right"
                                        data-aos-duration="1000"
                                        href="mailto:pabishek61001@gmail.com"
                                        target="_blank"
                                        rel="noopener noreferrer"
                                        variant="contained"
                                        color="primary"
                                        startIcon={<EmailIcon />}
                                        sx={{ margin: '8px' }}
                                    >
                                        Email
                                    </Button>
                                </Grid>
                                <Grid item>
                                    <Button
                                        data-aos="fade-right"
                                        data-aos-duration="1500"
                                        href="https://www.linkedin.com/in/abishek-periasamy-84a867260/"
                                        target="_blank"
                                        rel="noopener noreferrer"
                                        variant="contained"
                                        color="primary"
                                        startIcon={<LinkedInIcon />}
                                        sx={{ margin: '8px' }}
                                    >
                                        LinkedIn
                                    </Button>
                                </Grid>
                                <Grid item>
                                    <Button
                                        data-aos="fade-right"
                                        data-aos-duration="2000"
                                        href="https://api.whatsapp.com/send/?phone=919543445782&text&type=phone_number&app_absent=0"
                                        target="_blank"
                                        rel="noopener noreferrer"
                                        variant="contained"
                                        color="primary"
                                        startIcon={<WhatsAppIcon />}
                                        sx={{ margin: '8px' }}
                                    >
                                        WhatsApp
                                    </Button>
                                </Grid>
                                <Grid item>
                                    <Button
                                        data-aos="fade-right"
                                        data-aos-duration="2500"
                                        href="https://www.instagram.com/your-profile"
                                        target="_blank"
                                        rel="noopener noreferrer"
                                        variant="contained"
                                        color="primary"
                                        startIcon={<InstagramIcon />}
                                        sx={{ margin: '8px' }}
                                    >
                                        Instagram
                                    </Button>
                                </Grid>
                                <Grid item>
                                    <Button
                                        data-aos="fade-right"
                                        data-aos-duration="3000"
                                        href="https://www.facebook.com/your-profile"
                                        target="_blank"
                                        rel="noopener noreferrer"
                                        variant="contained"
                                        color="primary"
                                        startIcon={<FacebookIcon />}
                                        sx={{ margin: '8px' }}
                                    >
                                        Facebook
                                    </Button>
                                </Grid>

                            </Grid>
                        </Box>
                    </Container>
                </div>
                <Box component="footer" sx={{ padding: '20px 0', borderTop: '1px solid #ddd' }}>
                    <Container>
                        <Grid container spacing={2} justifyContent="center">
                            <Grid item xs={12} sm={8} textAlign="center">
                                <Typography variant="body2" sx={{ color: "white" }}>
                                    &copy; {new Date().getFullYear()} Abishek Periasamy. All Rights Reserved.
                                </Typography>
                            </Grid>
                        </Grid>
                    </Container>
                </Box>
            </div>
        </div>
    );
};

export default LandingPage;








