import React from 'react';
import { BrowserRouter, Routes, Route } from 'react-router-dom';

import './css/App.css';
import LandingPage from './screens/LandingPage';


const App = () => {
  return (
    <BrowserRouter>
      <Routes>
        <Route path='/' element={<LandingPage />}></Route>
      </Routes>
    </BrowserRouter>
  );
}

export default App;
